import React, { FunctionComponent } from "react"
import { PageProps } from "@src-types/page"
import { useIndexPageData } from "@hooks/use-index-page-data"
import { SermonsPage } from "@features/sermons/components/sermons-page/sermons-page"
import { Page } from "@components/page/page"
import { sermonIndexConfig } from "@features/sermons/sermon-index-config"
import { PageBreadcrumbs } from "@core/breadcrumbs"
import { PageOpenGraphImage } from "@core/constants"

export const Sermons: FunctionComponent<PageProps> = (props) => {
  const { header, featuredResources, metadata } = useIndexPageData("sermons")

  return (
    <Page
      {...props}
      metadata={{
        ...metadata,
        openGraphImage: PageOpenGraphImage.Sermons,
        description: undefined
      }}
      pageOptions={{
        headerVariant: "cream",
        breadcrumbs: PageBreadcrumbs.Sermons,
        defaultSearchIndex: sermonIndexConfig.defaultSearchIndex
      }}
    >
      <SermonsPage pageHeader={header} featuredList={featuredResources} />
    </Page>
  )
}

export default Sermons
